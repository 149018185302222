<template lang="pug">
  main.main-content.layout-main__centering.container

    DealInfo(v-if="item", :item="item")

    .previewDeal-container.previewDeal-width
      .previewDeal-container__column.previewDeal-container__green-block
        template(
          v-if="isAuthenticated && mustGoThroughKyc"
          v-loading="isProfileLoading"
        )
          KycNoVerificationMessage.previewDeal-container__kyc-no-confirmed-message
        template(v-else)
          .previewDeal-container__poll(v-loading="isLoading")
            CalculatorForm(
              v-if="item"
              :item="item"
              :price="price"
              :min="min"
              :accuracy="accuracy"
              :pay-all="isEventPayAll"
              :banks="ad && ad.payment_system.is_bank ? ad.banks : null"
              :initialFormData="initialFormData"
              @formUpdated="formUpdated"
            )

            span.form-error.mb-1(v-for="err in validationErrors")
              | {{err}}

            button.btn.btn__main.w-100(
              type="button"
              @click="createDeal()"
              :disabled="!isDealAvailable"
            ) {{labelButton}} {{crypto_amount}} {{cryptoCode}}

            <!-- button.button__payall(type="button", @click="handlePayAll", v-if="item.is_sale === false" ) продать все -->

            .d-flex.justify-content-center.align-self-center.mt-3
              input#check.check(type="checkbox", name="check", v-model="terms_accepted")
              label.check-label(for="check")
                | Я знаком с
                span.condtions(@click="conditionsWindowOpen = true")
                  |  условиями сделки

      Reviews(v-if="user", :userId="user.id", @handleAnalytics="handleAnalytics")

      el-dialog.standard-dialog(
        title="Условия сделки"
        :visible.sync="conditionsWindowOpen"
      )
        p(v-html="item.conditions || 'Нет особых условий.'")
        .dialog-footer(slot="footer")
          el-button(type="primary" @click="terms_accepted = true; conditionsWindowOpen = false") Ознакомлен
</template>

<script>
import { AD_MARKET, AD_EVENTS_SUB, AD_EVENTS_UNSUB, AD_DELETE } from '../store/actions/ad';
import { DEAL_CREATE } from '../store/actions/deal';
import { USER_PROFILE } from '../store/actions/user';
import { mapGetters } from 'vuex';
import adsMixin from '../components/mixins/ads';
import utils from '../components/mixins/utils';
import DealInfo from '../components/DealCalculator/DealInfo';
import CalculatorForm from '../components/DealCalculator/CalculatorForm';
import dealCalculator from '../components/DealCalculator/Mixins/dealCalculator';
import Reviews from '../components/DealCalculator/Reviews';
import KycNoVerificationMessage from '../components/AwesomeComponents/Account/Profile/KycNoVerificationMessage.vue';
import analytics from '@/components/mixins/analytics';

export default {
  name: 'DealCalculator',

  components: {
    Reviews,
    DealInfo,
    CalculatorForm,
    KycNoVerificationMessage,
  },

  mixins: [
    adsMixin,
    dealCalculator,
    utils,
    analytics
  ],

  data() {
    const fiat_amount = Number(this.$route.query.fiat_amount) || 0;
    const crypto_amount = Number(this.$route.query.crypto_amount) || 0;
    const terms_accepted = 'terms_accepted' in this.$route.query ? true : false;
    const bank_id = 'bank_id' in this.$route.query ? Number(this.$route.query.bank_id) : null;

    return {
      // deal create form
      fiat_amount,
      crypto_amount,
      terms_accepted,
      initialFormData: {
        fiat_amount,
        crypto_amount,
        bank_id,
      },
      validationErrors: [],
      id: null,
      bank_id,
      conditionsWindowOpen: false,
      // todo load from backend full resource
      accuracy: {
        BTC: 8,
        RUB: 0
      },

      //
      lang: {
        onDeletedCaption: 'Внимание',
        onDeletedText: 'Объявление было удалено или деактивировано, вы будете перенаправлены на главную',
        onPriceChangeCaption: 'Внимание',
        onPriceChangeText: 'Изменилась цена объявления с :oldVal :fiatCode на :newVal :fiatCode!'
      },
      isEventPayAll: false
    };
  },

  computed: {
    ...mapGetters([
      'getAd',
      'getProfile',
      'getBalance',
      'isAuthenticated',
      'loading',
      'getForDelete',
      'getUserCommissions'
    ]),

    // original Ad
    ad() {
      return this.getAd(this.id);
    },

    isLoading() {
      return this.loading(AD_MARKET) === 'loading'
        || this.loading(DEAL_CREATE) === 'loading';
    },

    //
    isDeleted() {
      return !!this.getForDelete[this.id];
    },

    // original price in float
    price() {
      return this.ad ? parseFloat(this.ad.price) : parseFloat(this.$route.query.price);
    },

    // original min fiat in float
    min() {
      return this.ad ? parseFloat(this.ad.min) : 0;
    },

    // formatted Ad
    item() {
      return this.ad ? this.formatPaymentSystems(this.formatAds([this.ad])).pop() : {};
    },

    //
    isDealAvailable() {
      return (!this.isAuthenticated || this.item.is_deal_available && this.item.is_deal_available.result)
        && !this.isLoading
        && this.fiat_amount > 0
        && this.ad
        && this.fiat_amount >= parseFloat(this.ad.min)
        && this.fiat_amount <= parseFloat(this.ad.max)
        && this.ad && (!this.ad.payment_system.is_bank || Boolean(this.bank_id))
        && this.validationErrors.length === 0
        && this.terms_accepted;
    },

    isProfileLoading() {
      return this.loading(USER_PROFILE) === 'loading';
    },
    
    mustGoThroughKyc() {
      return this.getProfile.is_kyc_required && !this.getProfile.is_kyc_confirmed;
    },
  },

  watch: {
    isDeleted(val) {
      if (val) {
        this.$alert(this.lang.onDeletedText, this.lang.onDeletedCaption, { showClose: false }).then(() => {
          this.$store.commit(AD_DELETE, { id: this.id });
          this.$router.push('/');
        });
      }
    },

    async price(newVal, oldVal) {
      if (oldVal > 0 && newVal !== oldVal) {
        this.$message.warning(this.replaceStringParams(this.lang.onPriceChangeText, {
          newVal, oldVal, fiatCode: this.fiatCode
        }), this.lang.onPriceChangeCaption);
      }

      try {
        const query = { ...this.$route.query, price: newVal };
        await this.$router.replace({
          ...this.$route,
          query,
          params: {
            ...this.$route.params,
            saveScrollPosition: true,
          },
        });
      } catch (error) {
        if (error.name !== 'NavigationDuplicated') {
          throw error;
        }
      }
    },

    async terms_accepted(isTermsAccepted) {
      const query = { ...this.$route.query };

      if (isTermsAccepted) {
        query.terms_accepted = null; // means key without value: /page?terms_accepted&...
      } else {
        delete query.terms_accepted;
      }

      try {
        await this.$router.replace({
          ...this.$route,
          query,
          params: {
            ...this.$route.params,
            saveScrollPosition: true,
          },
        });
      } catch (error) {
        if (error.name !== 'NavigationDuplicated') {
          throw error;
        }
      }
    },

    async bank_id(newVal) {
      if (newVal === undefined || newVal === null) {
        return;
      }

      try {
        const query = { ...this.$route.query, bank_id: newVal };
        await this.$router.replace({
          ...this.$route,
          query,
          params: {
            ...this.$route.params,
            saveScrollPosition: true,
          },
        });
      } catch (error) {
        if (error.name !== 'NavigationDuplicated') {
          throw error;
        }
      }
    },

    ad(ad) {
      if (ad && ad.banks && ad.banks.length === 1) {
        this.bank_id = ad.banks[0].id;
      }
    },
  },

  mounted() {
    this.id = parseInt(this.$route.params.id) || 0;
    this.$store.dispatch(AD_MARKET, { id: this.id });
    this.$store.dispatch(AD_EVENTS_SUB, { id: this.id });
  },

  beforeDestroy() {
    this.$store.dispatch(AD_EVENTS_UNSUB, { id: this.id });
  },

  methods: {
    //
    async formUpdated({ crypto_amount, fiat_amount, bank_id, keyOfChanged }) {
      this.fiat_amount = parseFloat(fiat_amount);
      this.crypto_amount = parseFloat(crypto_amount);
      this.bank_id = bank_id;
      this.checkForErrors();

      const query = { ...this.$route.query };
      
      if (keyOfChanged === 'fiat_amount') {
        query.fiat_amount = fiat_amount;
        delete query.crypto_amount;
      } else if (keyOfChanged === 'crypto_amount') {
        query.crypto_amount = crypto_amount;
        delete query.fiat_amount;
      }
      
      try {
        await this.$router.replace({
          ...this.$route,
          query,
          params: {
            ...this.$route.params,
            saveScrollPosition: true,
          },
        });
      } catch (error) {
        if (error.name !== 'NavigationDuplicated') {
          throw error;
        }
      }
    },

    //
    async createDeal() {
      if (!this.isAuthenticated) {
        this.$router.push({
          name: 'Login',
          query: { next: this.$route.fullPath },
        });
        return;
      }

      if (this.loading(DEAL_CREATE) === 'loading' || !this.isDealAvailable) {
        return;
      }

      try {
        await this.$store.dispatch(AD_EVENTS_UNSUB, { id: this.id });
        const response = await this.$store.dispatch(DEAL_CREATE, {
          ad_id: this.id,
          price: this.price,
          fiat_amount: parseFloat(this.fiat_amount),
          bank_id: this.bank_id
        });

        this.handleAnalytics('deal_open');
        await this.$router.push({name: 'Deal', params: {id: response.data.id}});
      } catch (reject) {
        this.$store.dispatch(AD_EVENTS_SUB, { id: this.id });
        let message = 'Не удалось создать сделку, обратитесь в поддержку';
        if (reject.errors) {
          message = Object.keys(reject.errors).map(key => reject.errors[key].join('<br>')).join('<br>');
        } else if (reject.message) {
          message = reject.message;
        }

        await this.$alert(message, {
          dangerouslyUseHTMLString: true,
          showClose: true,
          type: 'error'
        });
      }

    },

    /**
     * проверка ошибок
     * @returns void
     */
    checkForErrors() {
      this.validationErrors = [];

      if (!this.ad) {
        return;
      }

      const min = parseFloat(this.ad.min);
      const max = parseFloat(this.ad.max);

      if (isNaN(this.fiat_amount) || isNaN(this.crypto_amount)) {
        this.validationErrors.push('Некорректная сумма');
      }

      if (!this.item.is_sale) {
        const cryptoBalance = this.getBalance
        const balance = parseFloat(cryptoBalance.amount) - this.commissionClient(cryptoBalance.amount);

        if (this.crypto_amount > balance) {
          this.validationErrors.push(`Недостаточно ${this.cryptoCode} для этого обмена, сейчас у вас есть  ${balance.toFixed(8)} ${this.cryptoCode}`);
        }
      }

      if (this.fiat_amount <= 0 || min > 0 && this.fiat_amount < min) {
        this.validationErrors.push(`Мин. сумма: ${min} ${this.fiatCode}`);
      }

      if (max > 0 && this.fiat_amount > max) {
        this.validationErrors.push(`Макс. сумма: ${max} ${this.fiatCode}`);
      }
    },

    /**
     * расчет комиссии клиента от суммы сделки
     * @param amount
     * @returns number
     */
    commissionClient(amount) {
      return (parseFloat(amount) / 100) * this.getUserCommissions.total_calculated.client_commission.btc;
    },

    handleAnalytics(action) {
      const category = this.item.is_sale ? 'Ad_buy' : 'Ad_sell';
      this.sendAnalytics(category, action);
    },
    handlePayAll() {
      this.isEventPayAll = true
      if (this.isEventPayAll) {
        setTimeout(() => {
          this.isEventPayAll = false
        }, 1000)
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .form-error {
    color: $red;
    margin-bottom: 10px;
    text-align: center;
  }

  .form-error:last-of-type {
    margin-bottom: 20px;
  }

  .button__payall {
    border: 0;
    outline: 0;
    cursor: pointer;
    background: none;
    font-size: 14px;
    margin-top: 14px;
  }

  .button__payall:hover {
    text-decoration: underline;
    opacity: 0.8;
  }
</style>
