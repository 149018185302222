<template lang="pug">
  .previewDeal-header__character
    img(:src='user.avatar || defaultAvatar', :alt="user.login")
    .previewDeal-header__character-text
      span.previewDeal-header__character-text-name {{user.login}}
      .star-rating
        span.star-rating__star(v-for="number in 5", :class="{active: user.trust_coef >= number * 20}")
    .previewDeal-header__character-reviews
      span {{user.trust_coef}}%
</template>

<script>

export default {
  name: 'UserCardSmall',

  props: {
    user: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      defaultAvatar: '/img/default-avatar.png',
    }
  }
};
</script>
