<template lang="pug">
  div
    span(v-if="data.length === 0") Отзывы отсутствуют

    .previewDeal-review(
      v-for='review in data',
      :key='review.id'
    )
      img(:src="avatar(review.from_user.avatar)", width="40", height="40")
      .previewDeal-review__content
        .previewDeal-review__text
          span.previewDeal-review__text-name {{review.from_user.login}}
          p {{review.message}}
        .previewDeal-review__info
          time {{divideDate(review.created_at).date}}
          span.deal-rating-image.deal-rating-positive(:class="review.trust")

</template>

<script>
import utilsMixin from '../../mixins/utils';
import { mapGetters } from 'vuex';
import { AD_USER_REVIEWS } from '../../../store/actions/ad';
import cfg from '../../../../config';

export default {
  name: 'ReviewList',

  mixins: [utilsMixin],

  props: {
    userId: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      data: [],
      pages: {
        page: 1,
      },
    };
  },

  computed: {
    ...mapGetters({
      'reviews': 'getReviews',
      'avatar': 'getAvatar',
    }),
  },

  mounted() {
    this.getData(this.pages.page);
  },

  methods: {

    getData(page) {
      let filter = {
        trust: this.type,
        per_page: parseInt(cfg.per_page_min),
        page: page,
      };
      if (this.type === 'all') {
        filter = {per_page: parseInt(cfg.per_page_min), page: page};
      }
      this.$store.dispatch(AD_USER_REVIEWS, {userId: this.userId, filter}).then(() => {
        this.data = this.reviews.data;
        this.pages = this.reviews.pagination;
        this.$emit('contentDownloaded', this.pages.total);
      });
      return this;
    },

    changePage(page) {
      page = page || 1;
      this.getData(page);
    },

    prev() {
      let page = this.pages.current_page - 1;
      if (this.pageExists(page)) {
        this.changePage(page);
      }
    },

    next() {
      let page = this.pages.current_page + 1;
      if (this.pageExists(page)) {
        this.changePage(page);
      }
    },

    pageExists(page) {
      return page > 0 && page <= this.pages.total_pages;
    },
  },
};
</script>

<style scoped>
  .deal-rating-image {
    display: block;
    width: 20px;
    height: 20px;
    margin: 0 auto;
  }

  .deal-rating-positive.positive {
    background-image: url(/img/smile-good.698e1600.svg);
  }

  .deal-rating-positive.negative {
    background-image: url(/img/smile-bad.6b106113.svg);
  }

  .deal-rating-positive.neutral {
    background-image: url(/img/smile-ok.e08706a5.svg);
  }

  .previewDeal-review__content {
    width: 100%;
    justify-content: space-between;
  }

  .previewDeal-review__text {
    min-height: 62px;
  }
</style>
