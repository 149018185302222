import {mapGetters} from 'vuex';

export default {
  computed: {
    ...mapGetters([
      'isAuthenticated',
    ]),

    //
    user() {
      return this.item ? this.item.author : {};
    },

    //
    fiatCode() {
      return this.item.currency ? this.item.currency.code : '';
    },

    //
    cryptoCode() {
      return this.item.crypto_currency ? this.item.crypto_currency.code : '';
    },

    //
    tradeTypeLabel() {
      return this.item.is_sale ? 'Покупка' : 'Продажа';
    },

    //
    isSale() {
      return this.item.is_sale;
    },

    //
    labelButton() {
      return this.isSale ? 'Купить' : 'Продать';
    },
  },

  methods: {

  },
}
