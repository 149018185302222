<template lang="pug">
  .mb-3
    h3 Сколько вы хотите {{labelDealAction}}?

    form.d-e-blocks.w-100.pr-0.mb-3.take-set-input(novalidate)
      span {{labelFiatAction}}
      .block-wrap
        input(
          type="text"
          v-model="fiat_amount"
          lang="en-150"
          step="1"
          min="0"
          @input="debouncedCalcCrypto()"
          autocomplete="off"
        )
        .currency-name
          | {{fiatCode}}

    .d-e-blocks.w-100.pr-0.mb-3.take-set-input
      span {{labelCryptoAction}}
      .block-wrap
        input(
          type="text"
          v-model="crypto_amount"
          lang="en-150"
          step="0.00000001"
          min="0"
          @input="debouncedCalcFiat()"
          autocomplete="off"
        )
        .currency-name
          | {{cryptoCode}}

    .select-bank.mb-3(v-if="banks")
      .select-bank__label {{ labelBank }}
      el-select.select-bank__select(
        v-model="bank_id"
        placeholder="Выберите банк..."
        :disabled="banks.length === 1"
        @change="emitForm(fixedFieldKey)"
      )
        el-option.select-bank__option(
          v-for="bank in banks"
          :key="bank.id"
          :label="bank.title"
          :value="bank.id"
        )
          img.select-bank__option__icon(:src="bank.icon")
          span.select-bank__option__title {{ bank.title }}
</template>

<script>
import dealCalculator from './Mixins/dealCalculator';
import _ from 'lodash';
import {mapGetters} from "vuex";

export default {
  name: 'CalculatorForm',

  mixins: [
    dealCalculator,
  ],

  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    accuracy: {
      type: Object,
      default() {
        return {};
      },
    },
    price: {
      type: Number,
      default: 1,
    },
    min: {
      type: Number,
      default: 0,
    },
    payAll: {
      type: Boolean,
      default: false
    },
    banks: {
      type: Array,
      default: null,
    },
    initialFormData: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      // local form
      crypto_amount: 0,
      fiat_amount: 0,
      bank_id: null,
      fixedFieldKey: 'fiat_amount',

      lang: {
        calcForm: {},
      },
    };
  },

  computed: {
    ...mapGetters(['getBalance', 'getUserCommissions']),

    labelDealAction() {
      return this.isSale ? 'купить' : 'продать';
    },

    labelCryptoAction() {
      return this.isSale ? 'Покупаете' : 'Продаете';
    },

    labelFiatAction() {
      return this.isSale ? 'Оплачиваете' : 'Получаете';
    },

    labelBank() {
      return this.isSale ? 'Банк для оплаты' : 'Банк для получения';
    },
  },

  watch: {
    //
    min(newVal) {
      if (newVal && !this.fiat_amount && !this.crypto_amount) {
        this.fiat_amount = this.min;
        this.calcCrypto();
      }
    },
    payAll(newVal) {
      if (newVal) {
        const amount = parseFloat(this.getBalance.amount)
        let calc = amount / 100 * this.getUserCommissions.total_calculated.client_commission.btc
        const calc_min_fiat = this.price * calc
        const min_fiat = parseFloat(this.getUserCommissions.min_fiat[this.fiatCode])
        if (this.getUserCommissions.total_calculated.type === 'general') {
          if (calc_min_fiat < min_fiat) {
            calc = min_fiat / this.price
          }
        }
        this.crypto_amount = (amount - calc).toFixed(8)
        this.calcFiat()
      }
    },
    price() {
      this.recalculate();
    },
    fiatCode() {
      this.recalculate();
    },
    cryptoCode() {
      this.recalculate();
    },
    banks: 'bankAutoSelect',
  },

  created() {
    this.bankAutoSelect();

    this.debouncedCalcFiat = _.debounce(this.calcFiat, 500);
    this.debouncedCalcCrypto = _.debounce(this.calcCrypto, 500);
  },

  mounted() {
    if (this.initialFormData.fiat_amount) {
      this.fiat_amount = this.initialFormData.fiat_amount;
      this.calcCrypto();
    } else if (this.initialFormData.crypto_amount) {
      this.crypto_amount = this.initialFormData.crypto_amount;
      this.calcFiat();
    } else if (this.min) {
      this.fiat_amount = this.min;
      this.calcCrypto();
    }
  },

  methods: {
    //
    calcFiat() {
      this.crypto_amount = this.inputToValid(this.crypto_amount);
      let fiat_amount = (this.price * this.crypto_amount);
      this.fiat_amount = fiat_amount === 0 ? 0 : fiat_amount.toFixed(this.accuracy[this.fiatCode]);
      
      this.fixedFieldKey = 'crypto_amount';
      this.emitForm('crypto_amount');
    },

    //
    calcCrypto() {
      this.fiat_amount = this.inputToValid(this.fiat_amount);
      let crypto_amount = (this.fiat_amount / this.price);
      this.crypto_amount = crypto_amount === 0 ? 0 : crypto_amount.toFixed(this.accuracy[this.cryptoCode]);
      
      this.fixedFieldKey = 'fiat_amount';
      this.emitForm('fiat_amount');
    },

    //
    inputToValid(val) {
      val = parseFloat(val.toString().replace(/[^0-9.]/g, ''));
      if (isNaN(val)) {
        val = 0;
      }

      return val;
    },

    //
    emitForm(keyOfChanged) {
      this.$emit('formUpdated', {
        crypto_amount: parseFloat(this.crypto_amount),
        fiat_amount: parseFloat(this.fiat_amount),
        bank_id: this.bank_id,
        keyOfChanged,
      });
    },

    recalculate() {
      if (this.fixedFieldKey === 'fiat_amount') {
        this.calcCrypto();
      } else if (this.fixedFieldKey === 'crypto_amount') {
        this.calcFiat();
      }
    },

    bankAutoSelect() {
      const isBankAvailable = bankId => this.banks.some(({ id }) => id === bankId);

      if (this.banks) {
        if (this.banks.length === 1) {
          this.bank_id = this.banks[0].id;
          this.emitForm(this.fixedFieldKey);
        } else if ('bank_id' in this.initialFormData && isBankAvailable(this.initialFormData.bank_id)) {
          this.bank_id = this.initialFormData.bank_id;
          this.emitForm(this.fixedFieldKey);
        } else {
          const filteredBankId = this.$store.state.markets.filter.bank_id;
          if (typeof filteredBankId === 'number' && isBankAvailable(filteredBankId)) {
            this.bank_id = filteredBankId;
            this.emitForm(this.fixedFieldKey);
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.select-bank {
  width: 100%;

  .select-bank__select {
    width: 100%;
  }

  &__label {
    line-height: 28px;
    font-size: 14px;
    display: block;
    color: #7D7D7D;
    width: 100%;
  }

  &__option {
    display: flex;
    align-items: center;
    gap: 8px;

    &__icon {
      width: 20px;
      height: 20px;
    }
  }
}
</style>
