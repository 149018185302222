<template lang="pug">
  .previewDeal-container__column.wrapper_gray-border
    .previewDeal-container__reviews
      h3 Отзывы о продавце
      .previewDeal-container__reviews-container
        .tab.change-tabs
          span.tablinks(
            v-for='tab in tabs',
            :key='tab.id',
            :class="{'active': tab.id === activeTab}",
            @click='changeTab(tab.id)'
          ) {{tab.title}} {{tab.quantity}}

        .tabcontent(
          v-for='tab in tabs',
          :key='tab.id',
          :class="{'active': tab.id === activeTab}"
        )
          ReviewList(:userId="userId", :type="tab.id", @contentDownloaded="value => setQuantity(value, tab)")
</template>

<script>
import utilsMixin from '../../components/mixins/utils';
import ReviewList from "./Partials/ReviewList";

export default {
  name: 'Reviews',

  components: {ReviewList},

  mixins: [utilsMixin],

  props: {
    userId: {
      type: Number,
      default: 0,
    }
  },

  data() {
    return {
      activeTab: 'positive',
      tabs: [
        {id: 'positive', title: 'Положительные', quantity: 0},
        {id: 'negative', title: 'Отрицательные', quantity: 0},
        {id: 'neutral', title: 'Нейтральные', quantity: 0},
      ],
    }
  },

  methods: {
    changeTab(id) {
      this.activeTab = id;
      this.$emit('handleAnalytics', `review_open_${id}`);
    },

    setQuantity(quantity, tab) {
      this.tabs[this.tabs.indexOf(tab)].quantity = quantity;
    }
  },
};
</script>

<style scoped>
  .tabcontent.active {
    display: block;
  }

  .tabcontent {
    display: none;
  }
</style>
