<template lang="pug">
  .main-container.main-container_previewDeal-padding.previewDeal-width
    .previewDeal-header
      .previewDeal-header__left-column
        h3 {{tradeTypeLabel}}

        user-card-small(:user="user")

        .previewDeal-header__character-fastPayment
          span {{lang.deailInfo.time_to}} {{item.time}} {{lang.deailInfo.minutes}}
          .card-product__icon-bank.ml-auto(v-for="(payment, index) in payments", :key="index")
            span.card-product__icon-payment(:style="iconBg(payment.icon)", :title="payment.title")

          span.card-product__icon-bank(v-show="hasManyPayments", title="показать все", v-popover:popoverPayments)
            span.card-product__icon-payment-more
              | +
          el-popover(
            placement="top",
            width="auto",
            trigger="hover",
            ref="popoverPayments",
          )
            .previewDeal-header__character-fastPayment
              span.card-product__icon-bank(v-for="(payment, index) in item.payments.slice(1)", :key="index")
                span.card-product__icon-payment(:style="iconBg(payment.icon)", :title="payment.title")

      .previewDeal-header__right-column
        .previewDeal-header__item
          p {{lang.deailInfo.price}}
          span {{item.price}} {{fiatCode}}
        .previewDeal-header__item
          p {{lang.deailInfo.limits}}
          span {{item.min}} - {{item.max}} {{fiatCode}}
        .previewDeal-header__item
          p {{lang.deailInfo.location}}
          span {{item.country}}
</template>

<script>
import dealCalculator from './Mixins/dealCalculator';
import UserCardSmall from './Partials/UserCardSmall';

export default {
  name: 'DealInfo',

  components: {
    UserCardSmall,
  },

  mixins: [
    dealCalculator,
  ],

  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      lang: {
        deailInfo: {
          price: 'Цена',
          limits: 'Лимит',
          location: 'Местонахождение',
          time_to: 'до',
          minutes: 'мин',
        },
      },
      MAX_PAYMENTS_VISIBLE: 1,
    };
  },

  computed: {
    //
    payments() {
      return this.item.payments
        ? this.item.payments.slice(0, this.MAX_PAYMENTS_VISIBLE)
        : [];
    },

    //
    hasManyPayments() {
      return this.item.payments
        ? this.item.payments.length > this.MAX_PAYMENTS_VISIBLE
        : false;
    },
  },

  methods: {
    //
    iconBg(icon) {
      return `background: url("${icon}") no-repeat center; background-size: 100% auto;`;
    },
  },

};
</script>

<style scoped>

</style>
